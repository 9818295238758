.Register {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #003366;
}

.Register h1 {
  margin-top: 10%;
  font-size: 50px;
  color: white;
}

.mot img {
  width: 75px;
  height: 75px;
  border-radius: 20%;
  position: absolute;
  top: 2%;
  left: 2%
}

.reg-box {
  width: 400px;
  margin-top: 5%;
  padding: 50px;
  border: 1px solid #007864;
  border-radius: 5px;
  background-color: #001b40;
  margin-bottom: 100px;
}

.input-box {
  padding: 15px;
}

.input-box input {
  width: 94%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 15px;
}

.input-box input:focus {
  outline: none;
  border-color: dodgerblue;
}

.register-button {
  margin-top: 35px;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #007864;
  color: #fff;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
}

.register-button:hover {
  background-color: #008fa2;;
}

button:active {
  background-color: #0056b3;
}

@media (max-width: 640px) {
  .reg-box {
    width: 300px;
  }
}

@media (max-width: 550px) {
  .reg-box {
    width: 250px;
    margin-bottom: 50px;
  }

  .Register h1 { 
    font-size: 40px;
    margin-bottom: 60px;
  }
}