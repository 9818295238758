.add-icon {
  font-size: 1.5rem;
  color: #28a745;
  transition: color 0.3s ease;
}

.add-icon:hover {
  color: #218838;
}

.modal input,
.modal textarea {
  width: 100%;
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.submit-button {
  background-color: #02b564;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  float: right;
  margin-top: 10px;
}

.submit-button:hover {
  background-color: #c82333;
}

.application-table {
  width: 100%;
  margin: 20px 0;
  border-collapse: collapse;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  background-color: #ffffff;
  font-weight: bold;
  font-size: large;
}

.table-cell {
  flex: 1;
  padding: 10px;
  text-align: center;
}

.table-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  transition: background-color 0.3s ease;
  border-bottom: 0px solid #ccc;
}

.table-row:hover {
  background-color: #f5f5f5;
}

.add-application {
  cursor: pointer;
  font-size: 1.5rem;
  color: #28a745;
  transition: color 0.3s ease;
}

.add-application:hover {
  color: #218838;
}

.error {
  color: red;
}

.custom-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
  width: 150%;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  width: 150%;
}

.dropdown-toggle:hover + .dropdown-menu,
.dropdown-menu:hover {
  display: block;
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

.status-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 10px;
  margin-right: 15px;
}

.label-status {
  font-weight: bold;
  color: #333;
  font-size: 16px;
  margin-bottom: 8px;
  display: inline-block;
  margin-right: 20px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: white;
  padding: 50px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #999;
  transition: color 0.3s ease;
}

.close-button:hover {
  color: #333;
}

.icon {
  cursor: pointer;
  margin: 0 10px;
  color: #333;
}

.icon:hover {
  color: #007bff;
}

.status-cell {
  position: relative;
}

.status-cell .status-background {
  background-color: inherit;
  color: #fff;
  font-weight: bold;
  border-radius: 20px;
  padding: 10px 30px;
  display: inline-block;
  width: 80px;
}

.add-icon {
  font-size: 1.5rem;
  color: #28a745;
  transition: color 0.3s ease;
}

.add-icon:hover {
  color: #218838;
}

.modal input,
.modal textarea {
  width: 100%;
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.submit-button {
  background-color: #02b564;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  float: right;
  margin-top: 10px;
}

.submit-button:hover {
  background-color: #c82333;
}

.application-table {
  width: 100%;
  margin: 20px 0;
  border-collapse: collapse;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  background-color: #ffffff;
  font-weight: bold;
  
}

.table-cell {
  flex: 1;
  padding: 10px;
  text-align: center;
}

.table-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  transition: background-color 0.3s ease;
  border-bottom: 0px solid #ccc;
}

.table-row:hover {
  background-color: #f5f5f5;
}

.add-application {
  cursor: pointer;
  font-size: 1.5rem;
  color: #28a745;
  transition: color 0.3s ease;
}

.add-application:hover {
  color: #218838;
}

.error {
  color: red;
}

.custom-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
  width: 150%;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  width: 150%;
}

.dropdown-toggle:hover + .dropdown-menu,
.dropdown-menu:hover {
  display: block;
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

.status-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 10px;
  margin-right: 15px;
}

.label-status {
  font-weight: bold;
  color: #333;
  font-size: 16px;
  margin-bottom: 8px;
  display: inline-block;
  margin-right: 20px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}



.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #999;
  transition: color 0.3s ease;
}

.close-button:hover {
  color: #333;
}

.icon {
  cursor: pointer;
  margin: 0 10px;
  color: #333;
}

.icon:hover {
  color: #007bff;
}

.status-cell {
  position: relative;
}

.status-cell .status-background {
  background-color: inherit;
  color: #fff;
  font-weight: bold;
  border-radius: 20px;
  padding: 10px 30px;
  display: inline-block;
  width: 80px;
}

.sort-icons {
  margin-left: 5px;
}

.sort-icon {
  cursor: pointer;
  font-size: 0.8rem;
  margin: 0 2px;
  color: #333;
}

.sort-icon:hover {
  color: #007bff;
}

/* ------------ */

@media (max-width: 900px) {
  .icon {
    padding: 10px 10px;
  }
}

@media (max-width: 770px) {
.application-table {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.table-header {
  font-size: small;
}
.table-header, .table-row {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.table-cell {
  flex: 1;
  padding: 5px;
}

.add-application {
  cursor: pointer;
  color: white;
  text-align: center;
}

.icon {
  cursor: pointer;
  margin-left: 10px;
}

@media (max-width: 770px) {
  .application-table {
    flex-direction: column;
  }

  .table-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .table-cell {
    width: 100%;
    margin-bottom: 5px;
  }

  .add-application {
    width: 100%;
  }
}

.modal {
  max-width: 300px;
  width: 90%;
  margin: auto;
  padding: 20px;
}
.icon {
  font-size: 1.5em;
}

.submit-button, .close-button {
  padding: 10px 20px;
}
.modal input, .modal textarea {
  width: 90%; 
  padding: 10px;
  margin-bottom: 10px;
}
}

@media (max-width: 500px) {
  .table-header {
    font-size: x-small;
    padding-bottom: 30px;
  }

  .logo {
    margin-left: 0 !important;
  }
  .status-cell .status-background {
    padding: 10px 20px;
  }
  .icon {
    font-size: 1em;
  }
}


