.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #003366;
  color: #ffffff;
  font-weight: 600;
}

.logout {
  cursor: pointer;
}

.Login h1 {
  color: white;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-left: 15px;
  width: 200px;
}

.logo img {
  width: 60px;
  height: 60px;
  border-radius: 20%;
}

.navig-link {
  font-size: 30px !important;
  font-weight: bold;
  margin-left: 1px;
  color: #ffffff;

}

.nav-container {
  display: flex;
  justify-content: right;
  position: relative;
  width: 200px;
}

.spotify {
  margin-left: 20px;
  width: 55px !important;
  height: 55px !important;
  border-radius: 20%;
}

.profile {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 15px;
  text-align: center;
}

.profile-container {
  display: flex;
  /* align-items:last baseline; */
  gap: 50px;
  font-size: 20px;
  font-weight: 700;
}

.prof-pic img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.user-txt {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.user-txt p {
  margin: 5px;
}

.sort-btn {
  font-weight: 700;
  font-size: 22px;
}

.sort-btn:hover {
  color: yellow;
  cursor: pointer;
}

.sort-container {
  display: flex;
  justify-content: center;
  gap: 15px;
  flex-grow: 1;
}



.hidden-nav-link {
  display: none;
}

.nav-link, .hidden-nav-link, .navig-link {
  color: #fff;
  text-decoration: none;
  font-size: 25px;
  padding: 10px;
}

@media (max-width: 1100px) {
  .sort-btn {
    font-size: 15px;
  }

  .profile-container {
    font-size: 15px;
    gap: 30px;
  }
}

.mobile-sort-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: -15px;
  z-index: 9999;
  top: 60px;
  background-color: #003366;
  padding: 10px;
  border-radius: 8px;
  width: 80px;
}



@media (max-width: 1000px) {
  .sort-container {
    display: none;
  }

  .profile {
    display: flex;
  }

  /* Flex container to align hamburger and profile */
  .nav-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: auto;
  }

}

@media (max-width: 890px) {
  .nav-link, .hidden-nav-link, .navig-link {
    font-size: 20px !important;
  }
}

@media (max-width: 768px) {
  .hidden-nav-link:hover {
    color: #ffcc00;
  }

  .navig-link {
    display: none;
  }

  .hamburger {
    display: block;
  }

  /* .profile {
    display: none;
  } */

  .nav {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #373737;
    padding: 10px 0;
  }

  .hidden-nav-link {
    display: inline;
  }

  .nav-link, .hidden-nav-link, .navig-link {
    margin: 10px 10px;
    text-align: center;
    justify-content: flex-end;
    padding: 10px;
  }

  .nav-container {
    justify-content: flex-end;
  }

  .nav-container.mobile-open .nav {
    display: flex;
    width: fit-content;
    padding: 10px;
    flex-grow: 1;
    right: -20px;
    left: auto;
    z-index: 1000;
  }
}

@media (max-width: 640px) {
  .username {
    display: none;
  }

  .profile {
    width: 60px !important;
  }
}
